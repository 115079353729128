import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div
      className="termAndCond" style={{
        textAlign: 'left',
        padding: '5rem'
      }}>
      <h1
        style={{
          textAlign: 'center',
          textDecoration: 'underline'
        }}>Privacy Policy For Earnscape</h1>
      <p>Last Updated: February 19, 2025  </p>
      <p>
        This privacy policy (“Policy”) describes how Earnscape and its related companies (“Company”) collect, use, and share personal information of consumer users of this mobile application Earnscape (“App”). This Policy also applies to any of our other websites that post this Policy. This Policy does not apply to websites that post different statements.
      </p>

      <h2>WHAT WE COLLECT</h2>
      <p>We get information about you in a range of ways.</p>
      <p>
        <strong>Information You Give Us:</strong>We collect your telephone number and other information you directly give us on our App. We use this information to create an account for you using a One Time Password (OTP) sent to your telephone number. If you use our livestreaming feature, we may collect audio and video data from your microphone and camera, if enabled, to facilitate your streaming experience and communication with viewers.
      </p>
      <p>
        <strong>Information We Get From Others:</strong> We automatically log information about you and your device. For example, when using our App, we log your device’s operating system type, language, and the dates and times of access. When you use the livestreaming feature, we may also collect metadata related to your streams, such as duration, viewer interactions, and stream settings.
      </p>
      <p><strong>Cookies.</strong>We may log information using “cookies”. Cookies are small data files stored on your device by a website. </p>
      <p>
        <strong>Information Automatically Collected:</strong> We automatically
        log information about you and your device. For example, when using our
        App, we log your device’s operating system type, language, and the dates
        and times of access.
      </p>
      <p>
        <strong>Cookies:</strong> We may log information using “cookies”.
        Cookies are small data files stored on your device by a website.
      </p>

      <h2>USE OF PERSONAL INFORMATION</h2>

      <p>
        We use your personal information to operate, maintain, and improve our sites, products, and services. We use your personal information to:
      </p>
      <ul>
        <li>Create your account, through the OTP authentication.</li>
        <li>Process transactions you have made</li>
        <li>Respond to comments and questions and provide customer service</li>
        <li>
          Send information including confirmations, invoices, technical notices, updates, security alerts, and support and administrative messages.
        </li>
        <li>
          Communicate about promotions, upcoming events, and other news about products and services offered by us and our selected partners.
        </li>
        <li>Link or combine user information with other personal information. </li>
        <li>
          Protect, investigate, and deter against fraudulent, unauthorized, or illegal activity.
        </li>
        <li>
          <strong>livestreaming:</strong>
          If you enable livestreaming, we use your microphone and camera data (if activated) to improve the streaming experience and allow you to communicate with your viewers. Streams may be recorded for quality assurance, playback, or sharing purposes as outlined below.
        </li>
      </ul>

      <h2>SHARING OF PERSONAL INFORMATION</h2>
      <p>
        We do not share your personal information with third parties without your consent, except in the following circumstances or as described in this Policy:
      </p>
      <ul>
        <li>
          <strong>Service Providers:</strong> We may share your personal information with third parties for the purpose of providing services to us. These third parties are authorized to use your personal information only as necessary to provide these services to us. For livestreaming, this may include third-party platforms (e.g., YouTube, Twitch) that host or distribute your streams when you choose to share them
        </li>
        <li>
          <strong>
            Livestreaming Sharing:
          </strong>
          If you use the livestreaming feature, your streams (including audio, video, and any associated metadata) may be recorded and shared with third-party platforms such as YouTube and Twitch, based on your preferences. Streams may also be made available on the Earnscape website for viewing by other users or the public, depending on your settings. By using this feature, you consent to such sharing as configured by you. Earnscape retains recorded streams only as long as necessary to upload them to a third-party platform after the streaming session ends, after which we delete them from our servers.
        </li>

        <li>
          <strong>
            Compliance with Laws and Law Enforcement Requests; Protection of Our
            Rights:
          </strong>{' '}
          We may disclose your personal information as required by law, such as to comply with a subpoena, or similar legal process. We may also disclose your personal information when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request. This includes audio, video, or metadata from livestreams if legally required.
        </li>
      </ul>

      <h2>INFORMATION CHOICES AND CHANGES</h2>
      <p>
        Our marketing emails tell you how to “opt-out.” If you opt out, we may still send you non-marketing emails. Non-marketing emails include emails about your accounts and our business dealings with you.
      </p>
      <p>
        For livestreaming, you can control your microphone and camera access through your device settings and the App’s permissions. You may also adjust your stream settings to:
      </p>
      <ul>
        <li>Limit chat participation to subscribers only. </li>
        <li>Mute, kick, or ban individuals from your current stream or all future streams.  </li>
        <li>Determine whether your streams are recorded, shared with third-party platforms (e.g., YouTube, Twitch), or made available on the Earnscape website.  </li>
      </ul>
      <p>Please note that once a stream is shared with a third-party platform, it will be subject to that platform’s privacy policy and terms of service. Earnscape deletes recorded streams from our servers after they are uploaded to a third-party platform, typically shortly after the streaming session concludes. </p>

      <h2>YOUR RIGHTS UNDER GDPR (EU RESIDENTS)</h2>
      <p>If you are a resident of the European Union (EU), you have certain rights under the General Data Protection Regulation (GDPR): </p>
      <ul>
        <li><strong>Access: </strong>You can request a copy of the personal data we hold about you. </li>
        <li>
          <strong>Rectification: </strong>
          You can request correction of inaccurate or incomplete data.
        </li>
        <li>
          <strong>
            Erasure:
          </strong>
          You can request deletion of your personal data (e.g., account deletion), subject to legal exceptions.
        </li>
        <li>
          <strong>
            Restriction:
          </strong>
          You can request that we restrict processing of your data in certain cases.
        </li>
        <li>
          <strong>
            Data Portability:
          </strong>
          You can request your data in a structured, machine-readable format.
        </li>
        <li>
          <strong>
            Objection:
          </strong>
          You can object to processing of your data for marketing or other purposes based on legitimate interests.
        </li>
        <li>
          <strong>
            Withdraw Consent:
          </strong>
          Where processing is based on consent (e.g., livestream sharing), you can withdraw consent at any time.
        </li>
        <p>
          To exercise these rights, contact us at <a href="mailto:support@earnscape.com">support@earnscape.com</a> (mailto:<a href="mailto:support@earnscape.com">support@earnscape.com</a>). We will respond within one month, though this may be extended in complex cases. You also have the right to lodge a complaint with a supervisory authority in your country.
        </p>
      </ul>


      <h2>YOUR RIGHTS UNDER CCPA (CALIFORNIA RESIDENTS)</h2>
      <p>If you are a California resident, you have certain rights under the California Consumer Privacy Act (CCPA): </p>
      <ul>
        <li><strong>Access: </strong>You can request a copy of the personal data we hold about you. </li>

        <li>
          <strong>
            Know:
          </strong>
          You can request details about the personal information we collect, use, disclose, or sell about you.
        </li>
        <li>
          <strong>
            Delete:
          </strong>
          You can request deletion of your personal information, subject to legal exceptions
        </li>
        <li>
          <strong>
            Opt-Out of Sale:
          </strong>
          We do not sell your personal information. However, if this changes, we will notify you and provide an opt-out mechanism.
        </li>
        <li>
          <strong>
            Non-Discrimination:
          </strong>
          We will not discriminate against you for exercising your CCPA rights.
        </li>

        <p>
          To exercise these rights, contact us at <a href="mailto:support@earnscape.com">support@earnscape.com</a>. We will verify your identity and respond within 45 days, with a possible extension of an additional 45 days if needed. You may also designate 
          an authorized agent to make a request on your behalf with proper verification. 
          We welcome your comments or questions about this privacy policy. You may contact us at <a href="mailto:support@earnscape.com">support@earnscape.com</a>.
        </p>
      </ul>
  
      <h2>Changes to this Privacy Policy</h2>
      <p>
      We may change this privacy policy. If we make any changes, we will change the Last Updated date above. 
      </p>
      <p>
      Any questions regarding our Privacy Policy should be directed to our Data Protection Officer at <a href="mailto:support@earnscape.com">support@earnscape.com</a>.
      </p>

      <h2>END OF PRIVACY POLICY</h2>
    </div>
  );
};

export default PrivacyPolicy;
