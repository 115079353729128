import React from 'react';

const NewPP = () => {
  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <h1>Privacy Policy For Earnscape</h1>
      <p><strong>Last Updated: March 3, 2025</strong></p>

      <p>
        This privacy policy ("Policy") describes how Earnscape and its related companies ("Company") collect, use, and share personal information of consumer users of this mobile application Earnscape ("App"). This Policy also applies to any of our other websites that post this Policy. This Policy does not apply to websites that post different statements.
      </p>

      <h2>WHAT WE COLLECT</h2>
      <p>We collect information about you in several ways.</p>

      <h3>Information You Give Us</h3>
      <ul>
        <li><strong>Account Information:</strong> Your phone number, email, or Google account credentials, which are used to create and authenticate your account.</li>
        <li><strong>Livestream Interaction:</strong> If you watch a livestream, we may collect data related to your viewing activity, such as the streams you watch and interactions (like chat participation).</li>
      </ul>

      <h3>Information Automatically Collected</h3>
      <ul>
        <li><strong>Device Information:</strong> Information about your device, such as operating system type, device language, and access times.</li>
        <li><strong>Stream Data:</strong> We may collect metadata related to streams you watch, including duration and other settings relevant to the streaming experience.</li>
      </ul>

      <h3>Cookies</h3>
      <p>We may use cookies and similar technologies to enhance your experience while using our App. Cookies help us track usage patterns and improve app functionality.</p>

      <h2>USE OF PERSONAL INFORMATION</h2>
      <p>We use your personal information to operate, maintain, and improve our App and services. Specifically, we use your information to:</p>
      <ul>
        <li><strong>Create and manage your account:</strong> Through Google, email, or phone number login.</li>
        <li><strong>Allow you to watch streams:</strong> And interact with the stream content.</li>
        <li><strong>Send updates or notifications:</strong> Such as technical notices, account updates, and security alerts.</li>
        <li><strong>Provide customer support:</strong> And respond to your inquiries.</li>
      </ul>

      <h2>SHARING OF PERSONAL INFORMATION</h2>
      <p>We do not share your personal information with third parties without your consent, except in the following circumstances:</p>
      <ul>
        <li><strong>Service Providers:</strong> We may share your personal information with third-party vendors to support the operation of our App, such as for authentication purposes (e.g., Google authentication service).</li>
        <li><strong>Compliance with Laws:</strong> We may disclose your personal information when required by law, such as to comply with a legal request or protect our rights or the safety of others.</li>
      </ul>

      <h2>INFORMATION CHOICES AND CHANGES</h2>
      <p>You have choices regarding the personal information we collect:</p>
      <ul>
        <li><strong>Account Settings:</strong> You can manage your login credentials (Google, email, or phone number) through the settings page in the App.</li>
        <li><strong>Notifications:</strong> You can adjust your notification preferences to control what type of communication you receive.</li>
        <li><strong>Data Deletion:</strong> You can delete your account at any time by following the instructions in the App settings, which will remove all personal data associated with your account.</li>
      </ul>

      <h2>YOUR RIGHTS UNDER GDPR (EU RESIDENTS)</h2>
      <p>If you are a resident of the European Union (EU), you have certain rights under the General Data Protection Regulation (GDPR):</p>
      <ul>
        <li><strong>Access:</strong> You can request a copy of the personal data we hold about you.</li>
        <li><strong>Rectification:</strong> You can request corrections to inaccurate or incomplete data.</li>
        <li><strong>Erasure:</strong> You can request deletion of your personal data, subject to legal exceptions.</li>
        <li><strong>Restriction:</strong> You can request restriction of the processing of your data in certain cases.</li>
        <li><strong>Data Portability:</strong> You can request your data in a structured, machine-readable format.</li>
        <li><strong>Objection:</strong> You can object to processing for marketing purposes or based on legitimate interests.</li>
        <li><strong>Withdraw Consent:</strong> You can withdraw consent for specific activities, such as data sharing.</li>
      </ul>
      <p>To exercise these rights, contact us at <strong>support@earnscape.com</strong>. We will respond within one month, though this may be extended in complex cases.</p>

      <h2>YOUR RIGHTS UNDER CCPA (CALIFORNIA RESIDENTS)</h2>
      <p>If you are a California resident, you have certain rights under the California Consumer Privacy Act (CCPA):</p>
      <ul>
        <li><strong>Know:</strong> You can request details about the personal information we collect, use, disclose, or share about you.</li>
        <li><strong>Delete:</strong> You can request deletion of your personal information, subject to legal exceptions.</li>
        <li><strong>Opt-Out of Sale:</strong> We do not sell your personal information. If this changes, we will notify you and provide an opt-out mechanism.</li>
        <li><strong>Non-Discrimination:</strong> We will not discriminate against you for exercising your CCPA rights.</li>
      </ul>
      <p>To exercise these rights, contact us at <strong>support@earnscape.com</strong>. We will verify your identity and respond within 45 days, with a possible extension of an additional 45 days.</p>

      <h2>CHANGES TO THIS PRIVACY POLICY</h2>
      <p>We may change this privacy policy from time to time. If we make significant changes, we will update the "Last Updated" date above.</p>

      <p>If you have any questions about this privacy policy, please contact us at <strong>support@earnscape.com</strong>.</p>
    </div>
  );
};

export default NewPP;